import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Triangle } from '../assets/svgs/triangle.svg'
import { styled } from 'styled-components'
import Title from '../components/Title'
import BuildingHover from '../components/BuildingHover'
import Building from '../assets/images/home/projectsBuilding.png'
import Building2 from '../assets/images/projects/Viti.jpg'
import { Link } from 'react-router-dom'

const TriangleWrapper = styled.div`
    position: relative;
    z-index: 1;
    @media (max-width: 767.98px) {
        padding-top: 84.5px;
    }

`
const ProjectsWrapper = styled.div`
    margin-top: -5%;
    position: relative;
    z-index: 2;

    @media (max-width: 575.98px) {
        margin-top: 1rem; 
    }

`
function Projects() {
    return (
        <Container fluid={'lg'} className='px-4 px-xl-5'>
            <TriangleWrapper>
                <Triangle />
            </TriangleWrapper>
            <ProjectsWrapper>
                <Title>
                    Projects
                </Title>
                <Row className='g-4 g-xl-5'>
                    <Col xs={12} lg={6}>
                        <Link to={'/projects/ferizaj'}>
                            <BuildingHover
                                image={Building}
                                title={'Ferizaj'}
                            />
                        </Link>
                    </Col>
                    <Col xs={12} lg={6}>
                        <BuildingHover
                            image={Building2}
                            title={'Viti'}
                        />
                    </Col>

                </Row>
            </ProjectsWrapper>
        </Container>
    )
}

export default Projects