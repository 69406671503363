import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import { ReactComponent as Logo } from '../assets/svgs/logo.svg'
import { ReactComponent as Menu } from '../assets/svgs/menu.svg'
import HeaderModal from './HeaderModal'

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    background: #B2A997;
    z-index: 100;
    .logo {
        width: 100px;
        height: auto;
    }
`
function Navbar() {
    const [IsOpen, setIsOpen] = useState(false)
    const ToggleModal = () => {
        setIsOpen(!IsOpen)
        if (!IsOpen) {
            document.body.style.overflowY = 'hidden'
        }
        else {
            document.body.style.overflowY = ''
        }

    }
    return (
        <>
            <Wrapper>
                <Container fluid={'lg'} className='px-4 py-3'>
                    <Row className='justify-content-between gx-0'>
                        <Col xs={'auto'} className='my-auto'>
                            <Logo className='logo' />
                        </Col>
                        <Col xs={'auto'} lg={3} xxl={'2'} className='my-auto'>
                            <Menu className='menu' onClick={ToggleModal} style={{ cursor: 'pointer' }} />
                        </Col>
                    </Row>
                </Container> 
            </Wrapper>
            <HeaderModal ToggleModal={ToggleModal} IsOpen={IsOpen} />
        </>
    )
}

export default Navbar