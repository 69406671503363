import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as X } from '../assets/svgs/xBtn.svg'
import { ReactComponent as Logo } from '../assets/svgs/logo.svg'
import { HashLink } from 'react-router-hash-link'
import { Link, useLocation } from 'react-router-dom'

const ModalContainer = styled.div`
    background-color: #B2A997;
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 101;
    display: flex;
    flex-direction: column;
    transition: left 0.4s ease-in-out;


`
const XBtnContainer = styled.div` 
    cursor: pointer;
    text-align: end;
    padding-top: 2rem;
    padding-right: 2rem;

    svg {
        width: 30px;
        height:30px;
    }
`
const SidebarContent = styled.div`
    background: #B2A997;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

`
const TopLinksWrapper = styled.div`
    padding-top: 5rem;
    width: 100%;
    max-width: 150px;
`
const BottomLinksWrapper = styled.div`
    padding-bottom: 3rem;
    width: 100%;
    max-width: 150px;
`
const LinkWrapper = styled.div`
    padding-bottom: 1.5rem;

    a {
        color: #4C4847;
        text-decoration: none;    
        text-transform: uppercase;
        font-size: 16px;

    }

    a:hover {
        text-decoration: underline;
    }
`
const LogoWrapper = styled.div`
    width: 100%;
    max-width: 150px;
    svg {
        width: 100%;
    }
`
function HeaderModal({ ToggleModal, IsOpen }) {

    return (
        <ModalContainer style={{ left: IsOpen ? '0vw' : '150vw' }}>
            <XBtnContainer className='bigSectionBreak'>
                <X onClick={ToggleModal} />
            </XBtnContainer>
            <SidebarContent>
                <TopLinksWrapper>
                    <LinkWrapper>
                        <HashLink to='/#Home' onClick={ToggleModal}>
                            Home
                        </HashLink>
                    </LinkWrapper>
                    <LinkWrapper>
                        <HashLink to='/#AboutUs' onClick={ToggleModal}>
                            About us
                        </HashLink>
                    </LinkWrapper>
                    <LinkWrapper className="pb-0">
                        <HashLink to='/#Contact' onClick={ToggleModal}>
                            Contact
                        </HashLink>
                    </LinkWrapper>
                </TopLinksWrapper>
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                <BottomLinksWrapper>
                    <LinkWrapper>
                        <Link to='/projects' onClick={ToggleModal}>
                            projects
                        </Link>
                    </LinkWrapper>
                    
                    <LinkWrapper className="pb-0">
                        <HashLink to='/#Partners' onClick={ToggleModal}>
                            partners
                        </HashLink>
                    </LinkWrapper>
                </BottomLinksWrapper>
            </SidebarContent>
        </ModalContainer>
    )
}

export default HeaderModal