import React from 'react'
import { styled } from 'styled-components'


const TitleWrapper = styled.div`
    color: #4C4847;
    font-size: 48px;
    text-transform: uppercase;
    padding-bottom: 1.5rem;

    @media (max-width: 1399.98px) { 
    font-size: 45px;
    }

    @media (max-width: 1199.98px) {
        font-size: 40px;
    }

    @media (max-width: 991.98px) {
        font-size: 35px;
    }

    @media (max-width: 767.98px) {
        font-size: 35px;
    }

    @media (max-width: 575.98px) {
        font-size: 30px;
    }
`

function Title({ children }) {
    return (
        <TitleWrapper>
            {children}
        </TitleWrapper>
    )
}

export default Title