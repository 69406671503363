import React from 'react'
import { styled } from 'styled-components'
import Title from './Title'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    position: relative;
    :hover .overlay {
        visibility: visible;
    } 
`
const Overlay = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    transition: all 0.3s ease-in-out; 
    backdrop-filter: invert(80%);
    -webkit-backdrop-filter: invert(80%);
    
    ${Wrapper}:hover & {
        opacity: 1;
    }

`
const ImageWrapper = styled.img`
    width: 100%;
    position: relative;
    z-index: 1;
    :hover .overlay {
        visibility: visible;
    } 
`

function BuildingHover({ image, title }) {
    return (
        <>
            {/* <Link to={`/projects/${title}`}> */}
                <Wrapper>
                    <ImageWrapper src={image} alt="" />
                    <Overlay>
                        <Title>
                            {title}
                        </Title>
                    </Overlay>
                </Wrapper>
            {/* </Link> */}
        </>
    )
}

export default BuildingHover