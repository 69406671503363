import React from 'react'
import { styled } from 'styled-components'
import { ReactComponent as Logo } from '../assets/svgs/logo.svg'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'


const SidebarWrapper = styled.div`
    width: 100%;
`
const SidebarContent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 16.66666667%;

    background: #B2A997;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1399.98px) { 
        width: 25%;

    }
    
    @media (max-width: 1199.98px) {
        width: 25%;
    }

`
const TopLinksWrapper = styled.div`
    padding-top: 5rem;
    width: 100%;
    max-width: 150px;
    @media (max-width: 991.98px) {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
`
const BottomLinksWrapper = styled.div`
    padding-bottom: 3rem;
    width: 100%;
    max-width: 150px;
    @media (max-width: 991.98px) {
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
`
const LinkWrapper = styled.div`
    padding-bottom: 1.5rem;

    @media (max-width: 991.98px) {
        padding-bottom: 1rem;

    }
    a {
        color: #4C4847;
        text-decoration: none;    
        text-transform: uppercase;
        font-size: 16px;

        @media (max-width: 1399.98px) { 
            font-size: 14px;
        }
    }

    a:hover {
        text-decoration: underline;
    }
`
const LogoWrapper = styled.div`
    width: 100%;
    max-width: 150px;
    svg {
        width: 100%;
    }
`
function Sidebar() {
    return (
        <SidebarWrapper>
            <SidebarContent>
                <TopLinksWrapper>
                    <LinkWrapper>
                        <HashLink to='/#Home'>
                            Home
                        </HashLink>
                    </LinkWrapper>
                    <LinkWrapper>
                        <HashLink to='/#AboutUs'>
                            About us
                        </HashLink>
                    </LinkWrapper>
                    <LinkWrapper className="pb-0">
                        <HashLink to='/#Contact'>
                            Contact
                        </HashLink>
                    </LinkWrapper>
                </TopLinksWrapper>
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                <BottomLinksWrapper>
                    <LinkWrapper>
                        <Link to='/projects'>
                            projects
                        </Link>
                    </LinkWrapper>
                    
                    <LinkWrapper className="pb-0">
                        <HashLink to='/#Partners'>
                            partners
                        </HashLink>
                    </LinkWrapper>
                </BottomLinksWrapper>
            </SidebarContent>
        </SidebarWrapper>
    )
}

export default Sidebar