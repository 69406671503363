import { Col, Row } from 'react-bootstrap';
import './App.css';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import Projects from './pages/Projects';
import ScrollToTop from './hooks/ScrollToTop';
import OpenProject from './pages/OpenProject';

function App() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Row className='g-0'>
          {windowSize[0] < 767.98
            ? <Navbar />
            : <Col sm='3' xxl={2}> <Sidebar /> </Col>
          }

          <Col xs={12} md='9' xxl={10}>

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/projects/:title" element={<OpenProject />} />

            </Routes>
            <div id='Contact'>
              <Footer />
            </div>
          </Col>
        </Row>
      </BrowserRouter>
    </div>
  );
}

export default App;
