import React from 'react'
import Hero from '../sections/home/Hero'
import HomeAbout from '../sections/home/HomeAbout'
import Projects from '../sections/home/Projects'
import Partners from '../sections/home/Partners'

function Home() {
  return (
    <>
      <div id='Home'>

        <Hero />
      </div>

      <div id='AboutUs'>

        <HomeAbout />
      </div>
      <>
        <Projects />
      </>
      <div id='Partners'>

        <Partners />
      </div>
    </>
  )
}

export default Home