import React from 'react'
import { styled } from 'styled-components'


const Wrapper = styled.div`
    padding-top: 7rem;

    @media (max-width: 991.98px) {
        padding-top: 3rem;
    }

`
function SectionBreak({ children }) {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default SectionBreak