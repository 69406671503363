import React from 'react'
import { styled } from 'styled-components'


const TitleWrapper = styled.div`
    color: #4C4847;
    font-size: 24px;
    padding-bottom: 1.5rem;
    line-height: normal;

    @media (max-width: 1199.98px) {
            font-size: 20px;
        }

    @media (max-width: 575.98px) {
        font-size: 16px;
    }

`

function Subtitle({ children }) {
    return (
        <TitleWrapper>
            {children}
        </TitleWrapper>
    )
}

export default Subtitle