import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionBreak from '../../components/SectionBreak'
import Title from '../../components/Title'
import { styled } from 'styled-components'
import Building from '../../assets/images/home/projectsBuilding.png'
import Building2 from '../../assets/images/projects/Viti.jpg'
import BuildingHover from '../../components/BuildingHover'
import { Link } from 'react-router-dom'

const SmallLink = styled.div`
    a {   
        color: #B2A997;
        font-size: 14px;
    }
`
function Projects() {
    return (
        <Container fluid={'lg'} className='px-4 px-xl-5'>
            <SectionBreak>
                <Row>
                    <Col>
                        <Title>
                            Projects
                        </Title>
                    </Col>
                    <Col xs={'auto'}>
                        <SmallLink>
                            <Link to="/projects">
                                More projects -{'>'}
                            </Link>
                        </SmallLink>
                    </Col>
                </Row>
                <Row className='g-4 g-xl-5'>
                    <Col xs={12} lg={6}>
                        <Link to={'/projects/ferizaj'}>
                            <BuildingHover
                                image={Building}
                                title={'Ferizaj'}
                            />
                        </Link>
                    </Col>
                    <Col xs={12} lg={6}>
                        <BuildingHover
                            image={Building2}
                            title={'Viti'}
                        />
                    </Col>
                </Row>
            </SectionBreak>
        </Container>
    )
}

export default Projects