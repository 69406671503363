import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Squares } from '../../assets/svgs/squares.svg'
import { styled } from 'styled-components'
import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import Building from '../../assets/images/home/aboutBuilding.png'
import SectionBreak from '../../components/SectionBreak'

const SquaresWrapper = styled.div`
    margin-top: -25%;
    z-index: 3;
    position: relative;
    svg {
        max-width: 450px;
        width: 100%;
    }
`

const ImgContainer = styled.img`
    width: 100%;
`
function HomeAbout() {
    return (
        <Container fluid={'lg'} className='px-4 px-xl-5'>
            <Row className='gx-4 mb-5'>
                <Col xs={12} lg={8} xl={7} xxl={7} className='mt-auto order-2 order-lg-1'>
                    <SectionBreak>

                        <Title>
                            About us
                        </Title>
                        <Subtitle>
                            Neto Invest gjatë këtyre viteve ka synuar në përmbushjen e nevojave të klientëve të saj për banesa, ambiente tregtare, zyra, garazhe etj me një cilësi të lartë dhe standarte të certifikuara.
                            Eksperienca dhe cilësia shumë vjeçare ne fushën e ndërtimit dhe një staf i kualifikuar e ka bërë më të lehtë përmbushjen e këtij misioni.
                        </Subtitle>
                    </SectionBreak>
                </Col>
                <Col xs={6} lg={4} xl={5} xxl={'auto'} className=" order-1 order-lg-2 ms-auto">
                    <SquaresWrapper>
                        <Squares />
                    </SquaresWrapper>
                </Col>
            </Row>
            <ImgContainer src={Building} />
        </Container>
    )
}

export default HomeAbout