import React from 'react'
import { Container } from 'react-bootstrap'
import SectionBreak from '../../components/SectionBreak'
import Title from '../../components/Title'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { styled } from 'styled-components';
import Partner1 from '../../assets/images/home/Partner1.png'
import Partner2 from '../../assets/images/home/Partner2.png'
import Partner3 from '../../assets/images/home/Partner3.png'

const LogoPlaceholder = styled.div`
    img {
        max-width: 300px;
    }
`
function Partners() {
    return (
      
             <Container fluid={'lg'} className='px-4 px-xl-5'>
                <SectionBreak>
                    <Title>
                        Partners
                    </Title>

                    <Swiper
                        spaceBetween={90}
                        slidesPerView={'auto'}
                        className='partnerSwiper'
                    >
                        <SwiperSlide style={{ width: 'min-content' }}>
                            <LogoPlaceholder>
                                <img src={Partner1} alt="" />
                            </LogoPlaceholder>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'min-content' }}>
                            <LogoPlaceholder>
                                <img src={Partner2} alt="" />
                            </LogoPlaceholder>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: 'min-content' }}>
                            <LogoPlaceholder>
                                <img src={Partner3} alt="" />
                            </LogoPlaceholder>
                        </SwiperSlide>
                    </Swiper>

                </SectionBreak>
              </Container> 
    )
}

export default Partners