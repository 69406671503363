import React from 'react'
import { styled } from 'styled-components'
import Background from '../../assets/images/home/heroBuilding.png'
import { Container } from 'react-bootstrap'


const BgImageWrapper = styled.div`
  background: url(${Background}) no-repeat;
  height: 100vh;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
`
const Overlay = styled.div`
  background: #00000080;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

`
const TextContainer = styled.div`
  color: #b2a997;
  text-transform: uppercase;
  font-size: 72px;
  z-index: 2;
  position: relative;
  line-height: normal;
  width: 100%;

  @media (max-width: 1399.98px) { 
    font-size: 60px;
  }

  @media (max-width: 1199.98px) {
    font-size: 50px;
  }

  @media (max-width: 991.98px) {
    font-size: 40px;
  }

  @media (max-width: 767.98px) {
    font-size: 40px;
  }

  @media (max-width: 575.98px) {
    font-size: 35px;

  }
`

function Hero() {

  return (
    <BgImageWrapper>
      <Overlay />
      <Container fluid={'lg'} className='px-4 px-xl-5'>
        <TextContainer>
          Dreams are far more
          powerful when you
          invest in them.
        </TextContainer>
      </Container>
    </BgImageWrapper>
  )
}

export default Hero