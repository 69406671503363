import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Triangle } from '../assets/svgs/triangle.svg'
import { styled } from 'styled-components'
import Title from '../components/Title'
import { useParams } from 'react-router-dom'
import Subtitle from '../components/Subtitle'
import BigImage from '../assets/images/projects/FERIZAJ 1.jpeg'
import SmallImage1 from '../assets/images/projects/FERIZAJ 2.jpeg'
import SmallImage2 from '../assets/images/projects/FERIZAJ 3.jpeg'
import SmallImage3 from '../assets/images/projects/FERIZAJ 4.jpeg'

const TriangleWrapper = styled.div`
    position: relative;
    z-index: 1;
    @media (max-width: 767.98px) {
        padding-top: 84.5px;
    }

`
const ProjectsWrapper = styled.div`
    margin-top: -5%;
    position: relative;
    z-index: 2;
    @media (max-width: 575.98px) {
        margin-top: 1rem; 
    }

`
const SubtitleWrapper = styled.div`
    max-width: 800px;
    width: 100%;
`

const BigImageWrapper = styled.img`
    width: 100%;
`

function OpenProject() {
    const { title } = useParams()

    return (
        <Container fluid={'lg'} className='px-4 px-xl-5'>

            <TriangleWrapper>
                <Triangle />
            </TriangleWrapper>
            <ProjectsWrapper>
                <Title>
                    {title}
                </Title>
                <SubtitleWrapper>
                    <Subtitle>
                        Kompleksi i ri banesor në Ferizaj do të sjellë tipe të ndryshme të banesave, duke ju ofruar mundësi zgjedhjeje për banesën tuaj të ëndrrave!
                        Ndër tjera, makina juaj do të jetë e siguruar në parking, pasi ka hapësirë të madhe, deri 2 kate parkingje nëntokësore.
                        Vendosni për të ardhmen e familjes suaj duke siguruar banesën në Ferizaj!
                        Kompleksi i ri gjendet në rrugën: "Remzi Hoxha", Ferizaj (në afërsi të qendrës së mjekësisë familjare)
                    </Subtitle>
                </SubtitleWrapper>
                <div className='pb-4 pb-xl-5 pt-0 pt-xl-4'>
                    <BigImageWrapper src={BigImage} />
                </div>
                <Row className="gx-4 gx-xl-5 gy-4 gy-md-0 justify-content-center">
                    <Col xs={6} md={4}>
                        <img src={SmallImage1} alt="" className='img-fluid' />
                    </Col>
                    <Col xs={6} md={4}>
                        <img src={SmallImage2} alt="" className='img-fluid' />
                    </Col>
                    <Col xs={6} md={4}>
                        <img src={SmallImage3} alt="" className='img-fluid' />
                    </Col>

                </Row>
            </ProjectsWrapper>

        </Container>
    )
}

export default OpenProject