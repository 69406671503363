import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionBreak from './SectionBreak'
import { styled } from 'styled-components'
import Title from './Title'


const FooterWrapper = styled.div`
    background: #4C4847;
    padding-block: 2.5rem;
    div {
        color: #B2A997;
    }
`
const RowContainer = styled.div`
    padding-top: 3rem;

    @media (max-width: 575.98px) {
        padding-top: 0.5rem;
    }

    p {
        margin: 0px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
        font-size: 24px;
        
        @media (max-width: 1199.98px) {
            font-size: 20px;
        }
 
    }
    .last {
        text-decoration-line: none;
    }
`
function Footer() {
    return (
        <SectionBreak>
            <FooterWrapper>
                <Container fluid={'lg'} className='px-4 px-xl-5'>
                    <Title>
                        Contact
                    </Title>
                    <RowContainer>
                        <Row className="gy-5">
                            <Col>
                                <p>
                                    info@netoinvest.net
                                </p>
                                <p>
                                    +383 49 974 973

                                </p>
                                <p>
                                    +383 49 744 544
                                </p>
                                <p>
                                    rr.remzi hoxhaj, ferizaj
                                </p>
                            </Col>
                            <Col xs="auto">
                                <p className='last'>
                                    neto invest sh.p.k
                                </p>
                            </Col>
                        </Row>
                    </RowContainer>
                </Container>
            </FooterWrapper>
        </SectionBreak>
    )
}

export default Footer